import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/Gallery',
    name: 'Gallery',
    // Lazy loads gallery in background
    component: () => import( /* webpackChunName: "gallery" */ '../views/Gallery.vue')
},
]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,


    // To arrive at the top of pages when routing
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})

export default router