
<template>
  <v-container>
    <v-container class="justify-center">
      <h2>Contact</h2>
    </v-container>

    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="10"
        xl="8"
      >
        <!-- <p class="font-weight-light text-center">
          Messages will be sent to maribronseth@gmail.com
        </p> -->
        <v-form
          ref="form"
          v-model="validate"
          @submit.prevent="handleSubmit"
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <p
            class="hidden"
            style="display: none"
          >
            <label>Don't fill this out if youre human <input name="bot-field" /></label>
          </p>
          <v-container>
            <v-text-field
              filled
              type="text"
              name="subject"
              color="tertiary"
              label="Subject"
              :rules="rules.subject"
              v-model="form.subject"
            ></v-text-field>
            <v-text-field
              filled
              color="tertiary"
              type="email"
              name="email"
              label="Email"
              :rules="rules.email"
              v-model="form.email"
            ></v-text-field>
            <v-textarea
              auto-grow
              filled
              color="tertiary"
              counter="800"
              name="message"
              label="Message"
              class="text-left"
              :rules="rules.message"
              v-model="form.message"
            ></v-textarea>
          </v-container>

          <v-row justify="center">
            <v-col cols="12">
              <v-alert
                text
                class="pb-4 pt-4"
                transition="scale-transition"
                :type="alert.type"
                :value="alert.value"
                :color="alert.color"
              >
                {{ alert.text }}
              </v-alert>
            </v-col>
            <v-btn
              title="Submit"
              outlined
              color="tertiary"
              type="submit"
              :disabled="!validate || submitted"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-row>

        </v-form>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
        subject: null,
        message: null,
      },

      validate: false,
      submitted: false,

      alert: {
        value: false,
        color: "warning",
        type: "warning",
        text: "Something went wrong",
      },

      rules: {
        subject: [(v) => !!v || "This field is required", (v) => (v && v.length <= 50) || "Must be less than 50 characters"],
        email: [(v) => /.+@.+\..+/.test(v) || "The email must be formated correctly", (v) => (v && v.length <= 50) || "Must be less than 50 characters"],
        message: [(v) => !!v || "This field is required", (v) => (v && v.length <= 800) || 'Message must be less than 800 characters'],
      },
    };
  },

  methods: {
    // Tutorial showing how to set up the netlify form:
    // https://www.youtube.com/watch?v=omoYqKfvdfQ
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },

    handleSubmit() {
      fetch("/", {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: this.encode({
          "form-name": "contact",
          ...this.form,
        }),
      })
        .then(() => {
          this.alert.value = true;
          this.alert.type = "success";
          this.alert.color = "success"
          this.alert.text = "Message sent successfully";

          console.log("Message sent");
          this.submitted = true;
        })
        .catch((error) => {
          console.log(error.message);
          this.alert.value = true;
          this.alert.type = "warning";
          this.alert.color = "warning";
          this.alert.text = error.message;
        });
    },
  },
};
</script>