<template>
  <div>
    <!-- Floating button for scrolling to the top of the page -->
    <v-fade-transition>
      <v-btn
        fixed
        bottom
        right
        fab
        small
        color="tertiary"
        class="mr-1 mb-1"
        href="#"
        v-scroll="onScroll"
        v-show="fab"
      >
        <v-icon color="primary"> mdi-chevron-up </v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fab: false,
    };
  },
  methods: {
    onScroll() {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset;
      this.fab = top > 20;
    },
  },
}
</script>