<template>
  <div id="app">
    <v-app>
      <Header></Header>
      <main :style="{ background: $vuetify.theme.themes[theme].background }">
        <router-view />
        <FloatingButton></FloatingButton>
      </main>
      <Footer></Footer>
    </v-app>
  </div>
</template>



<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import FloatingButton from "./components/FloatingButton.vue"


export default {
  name: "App",
  components: {
    Header,
    Footer,
    FloatingButton,

  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  mounted() {
    this.selectTheme()
  },

  methods: {
    // Checks the browser's preference for light light/dark mode
    selectTheme() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }

  },
};
</script>