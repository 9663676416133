<template>
  <div>
    <PageLoader />

    <Featured class="spacer"></Featured>

    <About class="spacer-s"></About>

    <Contact class="spacer"></Contact>
  </div>
</template>

<script>
import PageLoader from "../components/PageLoader.vue";
import Featured from "./Featured.vue";
import About from "./About.vue";
import Contact from "./Contact.vue";

export default {
  components: {
    PageLoader,
    Featured,
    About,
    Contact,
  },
};
</script>

<style scoped>
</style>
