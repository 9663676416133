import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

// https: //vuetifyjs.com/en/styles/colors/#material-colors
const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true
        },

        // dark: true,

        themes: {
            light: {
                primary: "#FDFDFB",
                secondary: colors.grey.lighten3,
                tertiary: colors.shades.black,
                // For buttons and links to other pages
                accent: colors.amber.darken4,
                // For a tags and links
                anchor: colors.amber.darken4,
                // Computed propery in app main checks this value
                background: "#fffcf9",


                /*************/

            },

            dark: {
                primary: "#0f0f0f",
                secondary: colors.grey.darken4,
                tertiary: colors.shades.white,
                // For buttons and such
                accent: colors.amber.lighten3,
                // For a tags and links
                anchor: colors.amber.lighten3,
                // Computed propery in ap main checks this value
                background: "#0f0f0f",

            },
        },
    },
})

export default vuetify