<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="10"
        xl="8"
      >
        <v-row class="justify-center">
          <v-col
            cols="12"
            class="pt-8"
          >
            <h2>PAINTINGS</h2>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="4"
            lg="4"
            xl="4"
            v-for="(item, index) in images"
            :item="item"
            :key="index"
          >
            <v-card
              v-ripple="{ center: true }"
              elevation="8"
              color="primary"
              @click="openImage(index); dialog = true"
            >
              <picture alt="Painting/Illustration by Mari">
                <v-img
                  alt="Painting/Illustration by Mari"
                  aspect-ratio="1"
                  :src="item.path"
                  lazy-src="/images/gallery/placeholder.png"
                ><template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="accent"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </picture>
            </v-card>

          </v-col>
        </v-row>

        <!-- Pop out on mobile/tablet -->
        <v-dialog
          v-if="$vuetify.breakpoint.mobile"
          v-model="dialog"
          fullscreen
        >
          <v-card color="primary">
            <v-img
              height="100vh"
              width="100vw"
              contain
              alt="Painting/Illustration by Mari"
              :src="images[activeImage].path"
            >
              <v-row
                class="fill-height"
                align="center"
                no-gutters
              >
                <v-btn
                  color="primary"
                  fixed
                  top
                  right
                  fab
                  depressed
                  small
                  @click.stop="dialog = false"
                >
                  <v-icon color="tertiary">mdi-close</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  absolute
                  left
                  fab
                  depressed
                  small
                  @click="previousPhoto()"
                >
                  <v-icon
                    large
                    color="tertiary"
                  >mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  absolute
                  right
                  fab
                  depressed
                  small
                  @click="nextPhoto()"
                >
                  <v-icon
                    large
                    color="tertiary"
                  >mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </v-img>
          </v-card>
        </v-dialog>

        <!-- Pop out on desktop -->
        <v-dialog
          v-else
          v-model="dialog"
          height="80vh"
          width="80vw"
        >
          <v-card color="primary">
            <v-img
              contain
              height="80vh"
              alt="Painting/Illustration by Mari"
              :src="images[activeImage].path"
            >
              <v-row
                class="fill-height"
                align="center"
                no-gutters
              >
                <v-btn
                  class="mt-8"
                  color="primary"
                  absolute
                  top
                  right
                  fab
                  depressed
                  small
                  @click.stop="dialog = false"
                >
                  <v-icon color="tertiary">mdi-close</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  absolute
                  left
                  fab
                  depressed
                  small
                  @click="previousPhoto()"
                >
                  <v-icon
                    large
                    color="tertiary"
                  >mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  absolute
                  right
                  fab
                  depressed
                  small
                  @click="nextPhoto()"
                >
                  <v-icon
                    large
                    color="tertiary"
                  >mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </v-img>
          </v-card>
        </v-dialog>

        <v-row class="center pt-4">
          <v-btn
            color="accent"
            outlined
            @click="$router.push('/Gallery')"
          >Gallery</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      activeImage: 0,

      dialog: false,

      images: [
        { path: '/images/gallery/pafugl/image1.jpeg' },
        { path: '/images/gallery/maibarn/image5.jpeg' },
        { path: '/images/gallery/p_flow/image2.jpeg' },
        { path: '/images/gallery/hugin_munin/image1.jpeg' },
        { path: '/images/gallery/dollgirl/image1.jpeg' },
        { path: '/images/gallery/mors_beskyttelse/image1.jpg' },
      ],
    };
  },

  methods: {
    openImage(index) {
      this.activeImage = index;
    },

    nextPhoto() {
      this.activeImage = (this.activeImage + 1 < this.images.length ? this.activeImage + 1 : 0)
    },

    previousPhoto() {
      this.activeImage = (this.activeImage - 1 >= 0 ? this.activeImage - 1 : this.images.length - 1)
    },
  },

};
</script>

<style scoped>
.galleryImg {
  height: 450px;
}

@media screen and (max-width: 800px) {
  .galleryImg {
    height: 300px;
  }
}
</style>