<template>
  <div>
    <v-app-bar
      hide-on-scroll
      fixed
      flat
      color="primary"
      height="70"
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          justify="start"
          align="center"
        >
          <v-row
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-1 ml-4"
          >
            <router-link
              class="pa-4"
              text
              :to="{ name: 'Home'}"
            >
              <div class="navLink link">HOME</div>
            </router-link>
            <router-link
              class="pa-4"
              color="tertiary"
              text
              :to="{ name: 'Gallery'}"
            >
              <div class="navLink link">GALLERY</div>
            </router-link>
          </v-row>
        </v-col>

        <!-- Media -->
        <v-col justify="end">
          <v-row
            justify="end"
            align="center"
            no-gutters
            class="mt-3"
          >
            <div>
              <v-btn
                title="Instagram"
                href="https://www.instagram.com/maribro.no/"
                rel="noopener"
                target="_blank"
                color="tertiary"
                icon
              >
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
            </div>

            <b>|</b>

            <!-- NIGHT LIGHT MODE -->
            <div>
              <v-btn
                icon
                color="accent"
                title="Lightmode"
                v-if="$vuetify.theme.dark"
                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              >
                <v-icon>mdi-weather-night</v-icon>
              </v-btn>
              <v-btn
                icon
                color="accent"
                title="Darkmode"
                v-else
                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              >
                <v-icon>mdi-white-balance-sunny</v-icon>
              </v-btn>
            </div>

            <v-app-bar-nav-icon
              large
              color="tertiary"
              v-if="$vuetify.breakpoint.mobile"
              @click="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      right
      fixed
      temporary
      color="primary"
    >
      <v-list
        class="spacer"
        transparent
        dense
      >
        <v-list-item @click="$router.push('/')">
          <v-list-item-content>
            <v-list-item-title class="text-center">HOME</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('/Gallery')">
          <v-list-item-content>
            <v-list-item-title class="text-center">GALLERY</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      drawer: null,
    };
  },
};
</script>

<style scoped>
.navLink {
  text-decoration: none;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 5px;
  font-size: 1.5rem;
  color: var(--v-tertiary-base) !important;
}

.link:after {
  background: var(--v-tertiary-base) !important;
}
</style>